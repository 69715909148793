<div class="logo">
  <div class="logo-img">
    <picture>
      <source type="image/webp" srcset="/assets/img-min/Letter-K-icon.webp">
      <img src="/assets/img/Letter-K-icon.png" alt="Logo" />
    </picture>
  </div>
  <a routerLink="/about" class="pl-3 simple-text">
    Vladimir Kirienko
  </a>
</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]" [attr.aria-label]="menuItem.title + '.' ">
        <i class="material-icons">{{menuItem.icon}}</i>
        <p class="font-weight-bold">{{menuItem.title}}</p>
      </a>
    </li>
  </ul>
</div>
